/* slideoverNav css */
.slideover-container {
  position: fixed;
  right: -36%; 
  width: 36%; 
  height: 100%;
  background: linear-gradient(145deg, #f7f7f7, #dcdcdc); /* Off-white to light grey gradient */
  background: rgba(245, 245, 245, 0.9); /* Semi-transparent off-white */
  backdrop-filter: blur(12px); /* Adds a blur effect to the background */
  border-radius: 20px;
  box-shadow: 
    0 4px 10px rgba(0, 0, 0, 0.2),  /* Outer shadow */
    inset 0 2px 4px rgba(255, 255, 255, 0.8),  /* Inner white glow for glossiness */
    inset 0 -4px 6px rgba(0, 0, 0, 0.1); /* Subtle inner shadow at the bottom */
  transition: right 0.8s ease; /* Transition the right property */
  z-index: 20; 
}

.info-header {
  display: flex; 
  align-items: center; 
  gap: 10px; /* Adjust spacing between image and text */
  margin: 10% auto;
  padding: 20px; /* Add padding for better spacing inside the container */
  width: 70%;
  position: relative; /* Needed for the pseudo-elements */
  
  /* Enhanced Glassmorphism effect */
  background: rgba(36, 36, 36, 0.3); /* Slightly darker semi-transparent background */
  border-radius: 25px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2); /* Stronger shadow */
  backdrop-filter: blur(15px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(15px); /* Safari support */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Slight border to define the glass effect */
  overflow: hidden; /* Hide overflow of pseudo-elements */
}

.info-image {
  width: 60px; 
  height: 60px; 
  border-radius: 50%;
  object-fit: cover; 
  border: 2px solid #000000; 
}

.info-text {
  display: flex;
  flex-direction: column; 

}

.info-text .name {
  font-weight: bold; 
  color: black;
  font-family: 'Aquire', sans-serif;
  margin: 0; 
}

.info-text .email {
  color: white;
  font-family: 'AquireLight', sans-serif;
  font-size: smaller;
  margin: 0; 
}

.slideover-container.open {
  right: 0; /* Slide in the container */
}

  
  
.slideover-tabs-container {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
}

  
  .slideover-tab {
    padding-left: 1vw;
    color: black;
    padding-top: 1vw;
  }
  
  .slideover-letter {
    display: inline-block;
    color: #000000;
    font-size: 6vw;
    font-family: 'Aquire', sans-serif;
    line-height: 1;
    letter-spacing: 0.03em;

  }
  
  .slideover-links-container {
    position: fixed;
    bottom: 0;
    padding-left: 1vw;
    display: flex;
    flex-direction: row;
    padding-top: 3vw;
    justify-content: space-around;
  }
  
  .slideover-link {
    position: relative;
    margin-left: 1vw;
    height: 70%;
    padding: 1vw;
    color: black;
    font-family: 'AquireLight', sans-serif;
    font-size: 1vw;
    transition: color 0.3s ease; 
    cursor: pointer; 
    display: flex;
    align-items: center; 
    justify-content: center;
    border: 2px solid #000000;
    border-radius: 50px; 
    text-decoration: none; 
    box-sizing: border-box; 
    overflow: hidden;
  }
  
  .slideover-link::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0; 
    background: #353434;
    transition: height 0.3s ease; 
    z-index: -1;
  }
  
  .slideover-link.hover::before {
    height: 100%;
  }
  
  .slideover-link.hover {
    color: #c9c9c9; 
  }

  @media (max-width: 1300px) {
    .info-text .name{
      font-size: medium;
    }
    .info-text .email {
      font-size: x-small;
    }

  }
  
  @media (max-width: 768px) {
    .slideover-container {
      width: 100%;
      right: -100%;
    }
    .slideover-letter {
      font-size: 15vw;
      margin-top: 5vw;
    }
    .slideover-link {
      border: none;               
      border-radius: 0;          
      margin-left: 0;            
      padding: 0;                 
      font-size: 7vw;           
      font-family: 'Aquire', sans-serif;  
      color: black;             
      display: block;              
      text-align: left;           
      margin-bottom: 5vw;     
      cursor: pointer;            
    }
    .slideover-links-container {
      position: relative; 
      bottom: auto;     
      margin-top: auto;  

    }
    .slideover-container {
      display: flex;
      flex-direction: column;
    }
  }