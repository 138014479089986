.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



@media (max-width: 767px){
  .nav-list {
    display: none;
  }
  .hamburger {
    width: 4vw; 
    height: 4vw; 
    padding-right: 4vw;
    padding-top: 4vw;
  }
  
};


