@font-face {
    font-family: 'AquireLight';
    src: url('/AquireLight-YzE0o.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AquireBold';
    src: url('/AquireBold-8Ma60.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aquire';
    src: url('/Aquire-BW0ox.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}