/* ContactModal.css */
body {
    background: linear-gradient(45deg, #FC466B, #3F5EFB);
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
  }
  
  .container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  
  form {
    background: rgba(255, 255, 255, 0.3);
    padding: 3em;
    height: 20vw;
    width: 35vw;
    border-radius: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .contact-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.62); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    animation: slideDownFadeIn 0.5s ease forwards;
  }

  .contact-modal-overlay.closing {
    animation: slideUpFadeOut 0.5s ease forwards;
}

@keyframes slideUpFadeOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
  }

  @keyframes slideDownFadeIn {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  form p {
    font-weight: 600;
    color: #fff;
    opacity: 0.7;
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 60px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  form a {
    text-decoration: none;
    color: #ddd;
    font-size: 12px;
  }
  
  form a:hover {
    text-shadow: 2px 2px 6px #00000040;
  }
  
  form a:active {
    text-shadow: none;
  }

  form button{
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    width: 40%;
    font-size: 1rem;
    padding: 1em;
    margin-top: 1rem;
    margin-bottom: 2em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  form input {
    background: rgba(0, 0, 0, 0.6);
    width: 75%;
    padding: 1em;
    margin-bottom: 2em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  form:hover {
    margin: 4px;
  }

  textarea {
    background: rgba(0, 0, 0, 0.6);
    width: 80%;
    height: 30%;
    padding: 0.9em;
    padding-top: 0.2em;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    font-size: 1rem; 
    resize: none;
    vertical-align: top; 
    overflow: auto;
    line-height: 1.5; 
}
  
  ::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }

  .close-button {
    position: absolute;
    background: transparent;
    top: 1vw;
    left: 1vw;
    border: none;
    scale: 3.0;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    z-index: 1; /* Make sure it's above other elements */
  }
  
  .close-button:hover {
    color: #ddd;
  }
  
  .drop {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
    position: absolute;
    transition: all 0.2s ease;
  }
  
  .drop-1 {
    height: 80px;
    width: 80px;
    top: -20px;
    left: -40px;
    z-index: -1;
  }
  
  .drop-2 {
    height: 80px;
    width: 80px;
    bottom: -30px;
    right: -10px;
  }
  
  .drop-3 {
    height: 100px;
    width: 100px;
    bottom: 120px;
    right: -50px;
    z-index: -1;
  }
  
  .drop-4 {
    height: 120px;
    width: 120px;
    top: -60px;
    right: -60px;
  }
  
  .drop-5 {
    height: 60px;
    width: 60px;
    bottom: 170px;
    left: 90px;
    z-index: -1;
  }
  
  a,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
  
  @media (max-width: 768px){
    form{
      width: 65vw;
      height: 65vw;
    }
    .close-button{
      scale: 1.5;
      margin-left: 15px;
      margin-top: 10px;
    }

    form input{
      margin-bottom: 1em;
    }

    form p{
      margin-bottom: 1vw;
    }
    form button{
      width: 60%;
    }

    textarea{
      width: 86%;
      font-size: 0.85rem;
    }
    .drop-1 {
      height: 70px;
      width: 70px;
      top: -10px;
      left: -5px;
      z-index: -1;
    }
    
    .drop-2 {
      height: 70px;
      width: 70px;
      bottom: -20px;
      right: -10px;
    }
    
    .drop-3 {
      height: 80px;
      width: 80px;
      bottom: 80px;
      right: -30px;
      z-index: -1;
    }
    
    .drop-4 {
      height: 80px;
      width: 80px;
      top: -40px;
      right: -5px;
    }
    
    .drop-5 {
      height: 60px;
      width: 60px;
      bottom: 170px;
      left: 90px;
      z-index: -1;
    }
  }