html, body {
    overscroll-behavior: none;
    font-family: 'AquireLight', sans-serif;
}

.home-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.connecting-dots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0; 
    animation: fadeIn 2s ease-in forwards; 
    animation-delay: 4s;
}

.heading {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fafafa;
    font-weight: 300;
    z-index: 0;
    font-size: 3rem;
    text-align: center;
    cursor: default;
    width: 100%;
}

.heading-title{
  font-family: 'AquireBold', sans-serif;
    font-size: 8rem;
    font-weight: 700; 
    color: #FFFFFF; 
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
    line-height: 1.2;
}

.subHeading {
    opacity: 0; 
    animation: fadeIn 2s ease-in forwards;
    animation-delay: 2s;
    font-family: 'AquireLight', sans-serif;
    font-size: 2rem;                
    font-weight: bold;               
    font-style: italic;          
    color: #ffffff;             
    letter-spacing: 0.1rem;          
    text-transform: uppercase; 
  }

  .subHeading:nth-child(3) {
    animation-delay: 2.5s;
  }

  .navigate-button-container{
    display: flex;
    flex-direction: row;
  }

  .navigate-button {
    margin-top: 5%;
    width: 7vw;
    font-size: 1.2vw;
    background: rgba(245, 245, 245, 1.0);
    border-radius: 40px;
    height: 40%;
    color: black;
    position: relative;
    display: flex;
    justify-content: center;  
    align-items: center;     
    overflow: hidden; 
    opacity: 0;
    animation: fadeUp 1.5s ease-in forwards;
    animation-delay: 3s;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: 'AquireLight', sans-serif;
    padding: 10px 20px; 
    cursor: pointer;
}

.navigate-button:nth-child(2){
background-color: rgb(47, 47, 47);
color: white;
}



.bottomButtons {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  gap: 1rem;
}

.bottom-button {
  color: black;
  width: 50px; 
  height: 50px; 
  background-color: #f1f1f1; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }

  .bottom-button svg {
    font-size: 40px; /* Adjust the size as needed */
    width: 30px;
    height: 30px;
  }

  .button-text {
    font-size: 1.5rem; /* Customize the font size for the button text */
  }
  

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeUp{
    from{
        opacity: 0;
        margin-top: 5%
    }
    to {
        opacity: 1;
        margin-top: 1%;
    }
  }

  @media (max-width: 1100px) {
    .heading-title{
        font-size: 4rem;
    }
    .navigate-button{
        width: 100%;
        white-space: nowrap;
    }
    .bottom-button{
      margin-right: 2rem;
    }
  }